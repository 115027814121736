<ng-container [formGroup]="minorForm">
  <h3 class="text-center pb-4">Kiskorú részére</h3>
  <div class="grid formgrid">
    <div class="field grid col-12">
      <label for="guardian-name" class="col-3">Felelősségvállaló neve</label>
      <div class="col">
        <input class="w-full" id="guardian-name" type="text" pInputText formControlName="adultName" />
      </div>
      <small class="errorText my-auto" *ngIf="minorForm.controls.adultName.touched && minorForm.controls.adultName.errors?.['required']">Kötelező mező</small>
    </div>
    <div class="field grid col-12">
      <label for="guardian-birthday" class="col-3">Születési idő</label>
      <div class="col">
        <p-calendar inputId="guardian-birthday" formControlName="adultBirthday" dateFormat="yy.mm.dd"></p-calendar>
      </div>
      <small class="errorText my-auto" *ngIf="minorForm.controls.adultBirthday.touched && minorForm.controls.adultBirthday.errors?.['ageValid']">Érvénytelen születési dátum</small>
      <small class="errorText my-auto" *ngIf="minorForm.controls.adultBirthday.touched && minorForm.controls.adultBirthday.errors?.['underage']">Kiskorú nem lehet felelősségvállaló</small>
    </div>
    <div class="field grid col-12">
      <label for="minor-name" class="col-3">Kiskorú neve</label>
      <div class="col" >
        <input class="w-full" id="minor-name" type="text" pInputText formControlName="minorName" />
      </div>
      <small class="errorText my-auto" *ngIf="minorForm.controls.minorName.touched && minorForm.controls.minorName.errors?.['required']">Kötelező mező</small>
    </div>
    <div class="field grid col-12">
      <label for="guardian-birthday" class="col-3">Kiskorú születési idő</label>
      <div class="col">
        <p-calendar inputId="guardian-birthday" formControlName="minorBirthday" dateFormat="yy.mm.dd"></p-calendar>
      </div>
      <small class="errorText my-auto" *ngIf="minorForm.controls.minorBirthday.touched && minorForm.controls.minorBirthday.errors?.['ageValid']">Érvénytelen születési dátum</small>
      <small class="errorText my-auto" *ngIf="minorForm.controls.minorBirthday.touched && minorForm.controls.minorBirthday.errors?.['overage']">Nagykorú számára másik formanyomtatvány kitöltendő</small>
    </div>
    <div class="field-checkbox">
      <p-checkbox [binary]="true"  formControlName="agreeResponsibility" inputId="agreeResponsibility" />
      <label for="agreeResponsibility">Kijelentem, hogy a Vice-Beach felelősségvállalási szabályát  figyelmesen elolvastam, a benne foglalt feltételeket megismertem, tudomásul veszem és azokat magamra nézve  kötelező érvényűnek tartom, a nyilatkozatot saját akaratomból írom alá.​</label>
      <small class="errorText my-auto" *ngIf="minorForm.controls.agreeResponsibility.touched && minorForm.controls.agreeResponsibility.errors">Kötelező elfogadni</small>
    </div>
    <div class="field-checkbox">
      <p-checkbox [binary]="true"  formControlName="agreeMedia" inputId="agreeMedia" />
      <label for="agreeMedia">Beleegyezem, hogy a területen fotók és/vagy videofilm készülhet, amelyet a Vice-Beach programjainak népszerűsítése céljából díjmentesen felhasználhat.</label>
      <small class="errorText my-auto" *ngIf="minorForm.controls.agreeMedia.touched && minorForm.controls.agreeMedia.errors">Kötelező elfogadni</small>
    </div>
    <div class="field-checkbox">
      <p-checkbox [binary]="true"  formControlName="agreeGdpr" inputId="agreeGdpr" />
      <label for="agreeGdpr">​Jelen nyilatkozat aláírásával kifejezetten hozzájárulok ahhoz, hogy a fentiekben általam megadott személyes adataimat a VICE-BEACH a rá vonatkozó GDPR szabályai szerint kezelje.​</label>
      <small class="errorText my-auto" *ngIf="minorForm.controls.agreeGdpr.touched && minorForm.controls.agreeGdpr.errors">Kötelező elfogadni</small>
    </div>
    <div class="w-full">
      <signature-pad class="signaturePad" #signature [options]="signaturePadOptions" />
    </div>
  </div>
  <div class="grid justify-content-center pt-4">
    <p-button [routerLink]="'/menu'" class="col-2" label="Mégsem" severity="secondary"></p-button>
    <p-button [disabled]="processing" (click)="submitForm()" class="col-2" label="Véglegesít" severity="primary" ></p-button>
  </div>
</ng-container>
