import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, Validators, ReactiveFormsModule, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import { AngularSignaturePadModule, NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { RouterModule, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';

@Component({
  selector: 'app-minor',
  standalone: true,
  imports: [
    CommonModule,
    FloatLabelModule,
    ReactiveFormsModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    InputTextModule,
    AngularSignaturePadModule,
    RouterModule,
  ],
  providers: [],
  templateUrl: './minor.component.html',
  styleUrl: './minor.component.scss'
})
export class MinorComponent {

  @ViewChild('signature')
  public signaturePad: SignaturePadComponent | undefined;

  public signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 500,
    canvasHeight: 300
  };

  public processing: boolean = false;

  minorForm = this.formBuilder.group({
    adultName: ['', Validators.required],
    adultBirthday: [new Date(), this.createAgeValidator(true)],
    minorName: ['', Validators.required],
    minorBirthday: [new Date(), this.createAgeValidator(false)],
    agreeResponsibility: [false, Validators.requiredTrue],
    agreeMedia: [false, Validators.requiredTrue],
    agreeGdpr: [false, Validators.requiredTrue],
  })
  
  constructor(private formBuilder: FormBuilder, private readonly http: HttpClient, private readonly messageService: MessageService, private readonly router: Router) {}

  submitForm(): void {
    if (this.minorForm.invalid) {
      this.minorForm.markAllAsTouched();
      return;
    }

    this.processing = true;
    const content = {
      ...this.minorForm.value,
      signature: this.signaturePad?.toDataURL() ?? ''
    };
    this.http.post<any>('submit.php', content).subscribe(
      data => {
        this.messageService.add({ severity: 'success', summary: 'Sikereres mentés', detail: 'A kitöltött nyilatkozat sikeresen elmentve.' });
        setTimeout(() => { this.router.navigate(['/menu'])}, 3000);
      },
      error => {
        this.processing = false;
        this.messageService.add({ severity: 'error', summary: 'Sikertelen mentés', detail: error.error });
      });
  }

  createAgeValidator(adult: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = moment(control.value);
      const today = moment(0, "HH");
      const age = Math.floor(today.diff(value, 'years', true));
      if (value > today) {
        return { ageValid: true };
      }

      if (adult && age < 18) {
        return { underage: true };
      }

      if ((!adult) && age >= 18) {
        return { overage: true };
      }

      return null;
    }
  }
}
