import { Routes } from '@angular/router';
import { AdultComponent } from './adult/adult.component';
import { MenuComponent } from './menu/menu.component';
import { MinorComponent } from './minor/minor.component';
import { ViewerComponent } from './viewer/viewer.component';

export const routes: Routes = [
{ path: 'minor', component: MinorComponent },
{ path: 'adult', component: AdultComponent },
{ path: 'viewer', component: ViewerComponent },
{ path: '**', component: MenuComponent}
];
