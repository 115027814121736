<ng-container [formGroup]="loginForm">
  <div class="grid formgrid" >
    <ng-container *ngIf="!signatures">
      <div class="field grid col-12">
        <label for="name" class="col-3">Felhasználónév</label>
        <div class="col">
          <input class="w-full" id="name" type="text" pInputText formControlName="username" />
        </div>
      </div>
      <div class="field grid col-12">
        <label for="pass" class="col-3">Jelszó</label>
        <div class="col">
          <input class="w-full" id="pass" type="password" pInputText formControlName="password" />
        </div>
      </div>
    </ng-container>
  </div>
  <div class="grid justify-content-center pt-4">
    <p-button class="col-2" label="Frissítés" severity="success" (click)="login()" [disabled]="loginForm.invalid"></p-button>
    <p-button class="col-2" label="Letöltés" severity="secondary" (click)="download()" [disabled]="!signatures"></p-button>
  </div>
  <div class="row" *ngFor="let item of signatures">
    <div class="col-2" >
      <span>{{ item.modified * 1000 | date : 'medium'}}</span>
    </div>
    <div class="col-2" >
      <span *ngIf="item.adultName">{{ item.adultName }} ({{ item.adultBirthday | slice:0:10 }})</span>
    </div>
    <div class="col-2" >
      <span *ngIf="item.minorName">{{ item.minorName }} ({{ item.minorBirthday | slice:0:10 }})</span>
    </div>
    <div class="col-4" >
      <img [src]="item.signature" />
    </div>
  </div>
  
</ng-container>

