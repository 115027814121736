import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Signature } from './signature';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-viewer',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule
  ],
  templateUrl: './viewer.component.html',
  styleUrl: './viewer.component.scss'
})
export class ViewerComponent {

  public signatures: Signature[] | undefined;

  loginForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  })

  constructor(private formBuilder: FormBuilder, private readonly http: HttpClient, private readonly messageService: MessageService) {
  }

  public login(): void {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ' + btoa(this.loginForm.value.username + ':' + this.loginForm.value.password),
      }),
    }
    this.http.get<Signature[]>('view.php', httpOptions).subscribe(
      x => this.signatures = x.sort((left: Signature, right: Signature) => {
        return left.modified - right.modified;
      }),
      error => {
        this.messageService.add({ severity: 'error', summary: 'Hiba', detail: error.error.message });
        console.dir(error);
      });
  }

  public download(): void {
    const newBlob = new Blob([JSON.stringify(this.signatures ?? {})], { type: "application/json" });
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement("a");
    link.href = data;
    link.download = 'signatures.json';
    link.click();
  }

  public export(signature: Signature): void {
  
  }
}
